import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function SubscribePage() {
  return (
    <Layout language="ja" navbarBlur={true}>
      <SEO
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title="Subscribe"
      />
      <div className="container py-32">
        <h1 className="text-center">Subscribe (JA)</h1>
        <div className="text-center">[FORM]</div>
      </div>
    </Layout>
  );
}

export default SubscribePage;
